import GlobeAltIcon from "@heroicons/react/24/outline/GlobeAltIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, AppBar as MUIAppBar, useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import * as config from "../../../config/config";
import { localisation } from "../../../helpers/constant";
import { getLocaleCopies } from "../../../helpers/locale_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  getUser,
  getUserInfo,
  logout,
} from "../../../redux/actions/user_action";
import {
  CloseIconStyled,
  DrawerStyled,
  EmptyWrapper,
  IconButtonStyled,
  SelectStyled,
} from "./styles";

const settings = ["Profile", "Logout"];

function AppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  const copies = getLocaleCopies();

  const user = getUser() ? true : false;
  const mobileView = useMediaQuery("(max-width:767px)");
  const tabletView = useMediaQuery("(max-width:1279px)");
  const mdView = useMediaQuery("(min-width:960px)");
  const logo = config.assetDomain + "/images/logo/naikgaji-logo.png";

  const pages = [
    { title: copies.jobs, href: "/jobs", id: "pekerjaan" },
    { title: copies.companies, href: "/companies", id: "syarikat" },
    { title: copies.forEmployer, href: "/employer", id: "majikan" },
  ];

  const profileImageFromRedux = useSelector(
    (state) => state?.user?.user?.profileImageSquare
  );

  const usernameFromRedux = useSelector((state) => state?.user?.user?.name);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    switch (setting) {
      case "Profile":
        sendTrackingEvent({
          event: "CE_click-profile",
        });
        router.push("/profile");
        break;
      case "Logout":
        dispatch(logout());
        Cookies.set("utm_params", null);
        Cookies.set("isRegistered", false, { expires: 365 }); // Check if user is logged out and change cookie to false for
        if (router.asPath.includes("/profile")) {
          router.replace("/");
        } else {
          router.reload();
        }
      default:
        break;
    }

    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [selectedLang, setSelectedLang] = useState(
    router.locale ?? localisation.ENGLISH
  );

  const handleChangeLanguage = (event) => {
    if (event?.target?.value === selectedLang) return;

    setSelectedLang(event.target.value);

    router.push(`/${event.target.value}${router.asPath}`, undefined, {
      shallow: true,
      locale: event?.target?.value,
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getLanguageLabel = (localeProp) => {
    const matchedLabel = Object.keys(localisation)
      .find((locale) => localisation[locale] === localeProp)
      ?.toLowerCase()
      .split("_");

    const formatString = tabletView
      ? matchedLabel.includes("english")
        ? "EN"
        : "BM"
      : matchedLabel
          .map((splitLabel) => capitalizeFirstLetter(splitLabel))
          .join(" ");

    return formatString;
  };

  const handleRouteLogin = () => {
    if (!router.pathname.includes("employer")) {
      router.push("/login");
    } else {
      router.replace(`${process.env.NEXT_PUBLIC_ED_URL}/login`);
    }
  };

  const handleRouteSignup = () => {
    if (!router.pathname.includes("employer")) {
      router.push("/signup");
    } else {
      router.replace(`${process.env.NEXT_PUBLIC_ED_URL}/register/login-info`);
    }
  };

  useEffect(() => {
    if (!profileImageFromRedux || !usernameFromRedux) {
      dispatch(getUserInfo());
    }
  }, []);

  return (
    <MUIAppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: `1px solid ${Color.grey}`,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ display: { xs: "none", md: "flex", cursor: "pointer" } }}
            onClick={() => router.push("/")}
          >
            <img src={logo} width={70} height={70} />
          </Box>

          {/* for mobile view */}
          {/* contains the menu icon and the drawer itself */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerOpen}
              color="inherit"
              sx={{ zIndex: 10 }}
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <DrawerStyled
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerClose}
            >
              <EmptyWrapper
                container
                flexDirection="row"
                justifyContent="space-between"
                padding="5% 5% 0 5%"
              >
                <EmptyWrapper>
                  <img
                    src={logo}
                    width={70}
                    height={70}
                    onClick={() => router.push("/")}
                  />

                  <EmptyWrapper
                    container
                    flexDirection="column"
                    width="auto"
                    marginTop="10%"
                    alignItems="flex-start"
                    textAlign="flex-start"
                    paddingLeft="10px"
                  >
                    {pages.map((page) => (
                      <Typography
                        onClick={() => router.push(page.href)}
                        id={page.id}
                        key={page.href}
                        href={page.href}
                        textAlign="center"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "20px",
                          color: "black",
                        }}
                      >
                        {page.title}
                      </Typography>
                    ))}
                  </EmptyWrapper>
                </EmptyWrapper>

                <EmptyWrapper container alignItems="flex-start" width="auto">
                  <IconButtonStyled onClick={handleDrawerClose}>
                    <CloseIconStyled />
                  </IconButtonStyled>
                </EmptyWrapper>
              </EmptyWrapper>
              {router.asPath.includes("/employer") ? (
                <Grid
                  container
                  justifyContent="center"
                  gap={{ xs: "20px", lg: "100px" }}
                  sx={{ width: "100%", paddingX: "5%" }}
                >
                  <Button
                    variant="outlined"
                    id="log-masuk"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      width: "150px",
                    }}
                    onClick={handleRouteLogin}
                  >
                    Employer Login
                  </Button>
                  <Button
                    variant="contained"
                    id="daftar"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      width: "150px",
                    }}
                    onClick={handleRouteSignup}
                  >
                    Start Hiring Today
                  </Button>
                </Grid>
              ) : (
                !user && (
                  <Grid
                    container
                    justifyContent="center"
                    gap={{ xs: "20px", lg: "100px" }}
                    sx={{ width: "100%", paddingX: "5%" }}
                  >
                    <Button
                      variant="outlined"
                      id="log-masuk"
                      sx={{
                        borderRadius: "8px",
                        textTransform: "none",
                        width: "150px",
                      }}
                      onClick={handleRouteLogin}
                    >
                      {copies.login}
                    </Button>
                    <Button
                      variant="contained"
                      id="daftar"
                      sx={{
                        borderRadius: "8px",
                        textTransform: "none",
                        width: "150px",
                      }}
                      onClick={handleRouteSignup}
                    >
                      {copies.signup}
                    </Button>
                  </Grid>
                )
              )}
            </DrawerStyled>
          </Box>

          <Box
            display={{ xs: "flex", md: "none" }}
            sx={{
              width: "100%",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <img
              src={logo}
              width={70}
              height={70}
              onClick={() => router.push("/")}
            />
          </Box>

          {/* when desktop view, links is displayed besides the logo */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              gap: "10px",
              marginLeft: "20px",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.href}
                id={page.id}
                sx={{
                  color: page.href === "/employer" ? "#20C472" : "black",
                  textTransform: "none",
                  fontWeight: page.href === "/employer" ? "bold" : "normal",
                }}
                onClick={() => {
                  router.push(page.href);
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          {router.asPath.includes("/employer") ? null : (
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* This is basically the start adornment icon, which will only show when screen is tablet and above */}
              {!mobileView ? <GlobeAltIcon width={20} height={20} /> : null}
              <SelectStyled
                labelId="language-select"
                id="language-select"
                value={selectedLang}
                onChange={handleChangeLanguage}
                variant={"standard"}
                disableUnderline
                autoWidth
                sx={{
                  marginLeft: "10px",
                  fontFamily: "Karla",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                }}
                displayEmpty={mobileView}
                renderValue={(value) =>
                  mobileView ? (
                    <GlobeAltIcon
                      width={20}
                      height={20}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  ) : (
                    // instead of displaying "ms-my" etc., we format language label.
                    getLanguageLabel(value)
                  )
                }
              >
                {Object.values(localisation).map((lang) => (
                  <MenuItem
                    key={lang}
                    value={lang}
                    sx={{
                      fontFamily: "Karla",
                      fontSize: "14px",
                    }}
                    dense={tabletView}
                  >
                    {getLanguageLabel(lang)}
                  </MenuItem>
                ))}
              </SelectStyled>
            </Grid>
          )}

          {/* for the user icon */}
          {router.asPath.includes("/employer") && mdView ? (
            <Grid container sx={{ gap: "20px", width: "auto" }}>
              <Button
                variant="text"
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                }}
                onClick={handleRouteLogin}
              >
                Employer Login
              </Button>
              <Button
                variant="text"
                sx={{
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
                onClick={handleRouteSignup}
              >
                Start Hiring Today
              </Button>
            </Grid>
          ) : user ? (
            <Box sx={{ flexGrow: 0, marginLeft: "10px" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    // alt="User Profile Image"
                    src={
                      profileImageFromRedux
                        ? `https:${profileImageFromRedux}`
                        : "/static/images/avatar/2.jpg"
                    }
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Typography fontWeight={"bold"} padding={"6px 16px"}>
                  {usernameFromRedux}
                </Typography>
                {settings.map((setting) => {
                  const menuLabel =
                    setting == "Profile"
                      ? copies.profile
                      : setting == "Logout"
                      ? copies.logout
                      : "";

                  return (
                    <MenuItem
                      key={setting}
                      onClick={() => handleCloseUserMenu(setting)}
                      sx={{
                        minWidth: "120px",
                      }}
                    >
                      <Typography textAlign="center">{menuLabel}</Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          ) : !user && mdView ? (
            <Grid container sx={{ gap: "20px", width: "auto" }}>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  minWidth: "90px",
                  marginLeft: "15px",
                }}
                onClick={handleRouteLogin}
              >
                {copies.login}
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  minWidth: "90px",
                }}
                onClick={handleRouteSignup}
              >
                {copies.signup}
              </Button>
            </Grid>
          ) : null}
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
}

export default AppBar;
